
const routes = [
  {
    lists: [
      {
        title: 'Dashboard',
        icon: 'mdi-view-dashboard',
        route: 'Admin Dashboard'
      },
      {
        title: 'User Management',
        icon: 'mdi-account-box-multiple',
        route: 'Admin User Management',
        query: { search: '', page: 1, paginate: 10, role: '', status: '', created_from: '', created_to: '', timestamp: Date.now() },
      },
      {
        title: 'Enrollment',
        icon: 'mdi-notebook-multiple',
        route: 'Admin Course Enrollment',
        query: { search: '', page: 1, paginate: 10, learning_mode: '', status: '', created_from: '', created_to: '', timestamp: Date.now() },
      },
      {
        title: 'Tickets',
        icon: 'mdi-ticket-account',
        route: 'Admin Ticket',
        query: { search: '', page: 1, paginate: 10, category: '', date_from: '', date_to: '', status: '', timestamp: Date.now() },
      },
      // {
      //   title: 'Class Section',
      //   icon: 'mdi-school',
      //   route: 'Admin Class Section'
      // },
      // {
      //   title: 'Enrollees',
      //   icon: 'mdi-account-multiple',
      //   route: 'Admin Enrollees'
      // },
      // {
      //   title: 'Invoices',
      //   icon: 'mdi-currency-usd',
      //   route: 'Admin Invoices'
      // },
    ]
  },
  {
    subheader: 'CUSTOMIZATION',
    lists: [
      {
        title: 'Class Category',
        icon: 'mdi-account-supervisor-circle-outline',
        route: 'Admin Class Category'
      },
      {
        title: 'Registration',
        icon: 'mdi-playlist-edit',
        route: 'Admin Registration'
      },
    ]
  },
  {
    subheader: 'Tracking',
    lists: [
      {
        title: 'Import Logs',
        icon: 'mdi-clipboard-text-outline',
        route: 'Admin Import Logs'
      },
    ]
  },
  // {
  //   subheader: 'COMMUNICATION',
  //   lists: [
  //     {
  //       title: 'Inbox',
  //       icon: 'mdi-mail',
  //       route: 'Teacher Live Session'
  //     },
  //   ]
  // },
  // {
  //   subheader: 'HELP DESK',
  //   lists: [
  //     {
  //       title: 'Ticketing Support',
  //       icon: 'mdi-ticket-account',
  //       route: 'User Tickets' 
  //     },
  //     {
  //       title: 'FAQS',
  //       icon: 'mdi-help-circle',
  //       route: ''
  //     },
  //   ]
  // },
  // {
  //   subheader: 'ACCOUNT AND SECURITY',
  //   lists: [
  //     {
  //       title: 'Account Settings',
  //       icon: 'mdi-account',
  //       route: 'Teacher Settings' 
  //     },
  //   ]
  // },
]

export default {
  install(Vue) {
    Vue.prototype.$adminRoutes = routes
  }
}