import router from "@/router"

export function usersMutation(state, payload) {
  state.users = payload
}

export function registrationTablesMutation(state, payload) {
  state.registration_tables = payload
}

export function columnsMutation(state, payload) {
  state.columns = payload
}

export function classesMutation(state, payload) {
  state.classes = payload
}

export function coursesMutation(state, payload) {
  state.courses = payload
}

export function filteredUsersMutation(state, payload) {
  state.filtered_users = payload
}

export function tenantMutation(state, payload) {
  state.tenant = payload
}

export function enrolleesMutation(state, payload) {
  state.enrollees = payload
}

export function ticketsMutation(state, payload) {
  state.tickets = payload
}

export function ticketCountMutation(state, payload) {
  state.ticketCount = payload
}

export function logMutation(state, payload) {
  state.logs = payload
}
